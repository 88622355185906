import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import { ParagraphBalanced } from "../theme/typography";

const StyledContactBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	border-top: 1px solid ${(props) => props.theme.colors.neutral8};
	color: ${(props) => props.theme.colors.neutral2};
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		align-items: flex-end;
		flex-direction: row;
		justify-content: center;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		gap: 3rem;
		padding: 3rem;
	}
`;

const ContactOption = styled.div`
	box-sizing: border-box;
	flex: 0 1 auto;
	max-width: 36rem;
`;

const Kicker = styled.h6`
	${(props) => props.theme.typography.heading2XS};
	color: ${(props) => props.theme.colors.yellow4};
	margin: 0.5rem 0;
	text-transform: uppercase;
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingM};
	margin: 1.5rem auto;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.headingL};
	}
`;

const FlexButton = styled(Button)`
	flex: 0 0 auto;
`;

export class ContactBlock extends React.Component {

	render() {
		return (
			<StyledContactBlock id="contact">
				<ContentContainer>
					<ContactOption>
						<Kicker>Interested?</Kicker>
						<Heading>Join our Discord!</Heading>
						<ParagraphBalanced>Ask&nbsp;questions, share your feedback, keep&nbsp;up with development news — be a part of Tressym's community!</ParagraphBalanced>
						<ButtonContainer>
							<FlexButton label="Join Discord server" link="https://discord.gg/rRCda9KUWR"/>
						</ButtonContainer>
					</ContactOption>
					<ContactOption>
						<Heading>Tell me what you think!</Heading>
						<ParagraphBalanced>I'd love to hear your feedback on Tressym designs! What do you like, what would you change?</ParagraphBalanced>
						<ButtonContainer>
							<FlexButton label="Send email" link="mailto:contact@tressym.eu"/>
						</ButtonContainer>
					</ContactOption>
				</ContentContainer>
			</StyledContactBlock>
		);
	}
}