import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { ParagraphSmall } from "../theme/typography";

const StyledBengalSpecBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	padding: 1.75rem 1.5rem;
	max-width: 32rem;
	margin: 0 auto;

	:first-child {
		margin-top: 0;
	}

	${LabeledDataRow} {
		margin-top: 2rem;
	}
`;

const Title = styled.h3`
	${(props) => props.theme.typography.headingL};
	margin-top: 0;
`;

const Info = styled.h3`
	${(props) => props.theme.typography.headingS};
	margin-bottom: 0.5rem;
	margin-top: 2rem;
`;

const Example = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.neutral4};
	margin-top: 0.5rem;
`;

export class BengalSpecBlock extends React.Component {

	render() {
		return (
			<StyledBengalSpecBlock>
				<ContentContainer>
					<Title>Specifications</Title>
					<LabeledDataRow>
						<LabeledData
							colorContext="light"
							label="Frame weight"
							data="14 g"
							supplemental="With 12mm stack screws."
						/>
						<LabeledData
							colorContext="light"
							label="Canopy weight"
							data="3.50 g"
						/>
					</LabeledDataRow>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Stack mounting"
							data="25x25"
							supplemental="25.5 mm x 25.5 mm"
						/>
						<LabeledData
							size="m"
							colorContext="light"
							label="Motor mounting"
							data="9 mm"
						/>
					</LabeledDataRow>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Material"
							data="T700 carbon fiber"
							supplemental='Plain weave, matte finish.'
						/>
					</LabeledDataRow>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Plate thickness"
							data="2 mm"
						/>
					</LabeledDataRow>
					<Info>Fits 25x25 AIOs</Info>
					<Example>A cutout on the plate allows access to downward-facing USB connectors, found on many 2S AIOs.</Example>
					<Info>Fits the DJI O4 and Nano analog cameras</Info>
					<Example>You can also use tinywhoop cameras with the <Link to="/parts/joker">Tressym Joker canopy</Link> for an even lighter build. I would NOT suggest this however, because it might not be crash-proof.</Example>
				</ContentContainer>
			</StyledBengalSpecBlock>
		);
	}
}