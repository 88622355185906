import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import { Paragraph, ParagraphSmall } from "../theme/typography";
import products from '../data/products.json';

const StyledBuyBlock = styled.section`
	background: ${(props) => props.theme.colors.yellow6};
	color: ${(props) => props.theme.colors.neutral1};
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	flex-direction: column;
	margin: 0 auto;
	max-width: 36rem;
	gap: 1.5rem;
	padding: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		gap: 3rem;
		padding: 3rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingM};
	margin-top: 0;
	margin-bottom: 1.5rem;
	text-wrap: balance;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.headingL};
	}
`;

const BuyParagraph = styled(Paragraph)`
	text-wrap: balance;
`;

const FlexButton = styled(Button)`
	background: ${(props) => props.theme.colors.neutral2};
	flex: 0 0 auto;

	&:hover {
		background: ${(props) => props.theme.colors.neutral1};
	}
`;

const ButtonAndDescription = styled.div``;

const ButtonDescription = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.neutral1};
	margin-top: 1rem;
	margin-bottom: 0;
	text-align: center;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		text-align: left;
	}
`;

export class ServalBuyBlock extends React.Component {

	render() {
		const servalData = products.find((product) => product.id === "serval");

		return (
			<StyledBuyBlock id="contact">
				<ContentContainer>
					<Heading>{servalData.buySectionHeading}</Heading>
					<BuyParagraph>{servalData.buySectionParagraph}</BuyParagraph>
					<ButtonAndDescription>
						<ButtonContainer>
							<FlexButton label="Buy a Serval" link={servalData.buyLink}/>
						</ButtonContainer>
						<ButtonDescription>{servalData.stockStatus.text}</ButtonDescription>
					</ButtonAndDescription>
				</ContentContainer>
			</StyledBuyBlock>
		);
	}
}