import styled from 'styled-components';

export const Paragraph = styled.p`
	${(props) => props.theme.typography.bodyXS};
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
`;

export const ParagraphBalanced = styled(Paragraph)`
	text-wrap: balance;
`;

export const ParagraphSmall = styled.p`
	${(props) => props.theme.typography.body2XS};
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
`;

export const HeroParagraph = styled.p`
	${(props) => props.theme.typography.bodyS};
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	text-wrap: balance;
`;

export const Kicker = styled.p`
	${(props) => props.theme.typography.heading2XS};
	text-transform: uppercase;
`;

export const MainTitle = styled.h2`
	${(props) => props.theme.typography.headingInfiltratorXL};
	margin-bottom: -1rem;
	margin-top: 0;
	
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		${(props) => props.theme.typography.headingInfiltrator3XL};
		margin-bottom: -1rem;
	}
	
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.headingInfiltrator4XL};
		margin-bottom: -1rem;
	}
`;

export const UnorderedList = styled.ul`
    list-style-type: none;
    padding-left: 0;
    margin: 0;
`;

export const UnorderedListItem = styled.li`
	display: block;
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
    position: relative;

    &::before {
        content: "◆";
        color: ${props => props.theme.colors.yellow4};
		font-size: 0.75rem;
        left: 0.05rem;
        position: absolute;
		top: -0.04em;
    }
`;