import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header } from './Header';
import { ContactBlock } from './ContactBlock';
import { BengalHeaderBlock } from './BengalHeaderBlock';
import { BengalTextBlock } from './BengalTextBlock';
import { BengalSpecBlock } from './BengalSpecBlock';
import { BengalOpenSourceBlock } from './BengalOpenSourceBlock';
import { BengalBuildBlock } from './BengalBuildBlock';
import { BengalBuyBlock } from './BengalBuyBlock';

const BengalPageWrapper = styled.div`
    margin-top: 3rem;
    height: calc(100vh - 3rem);
    overflow-y: scroll;
    scroll-snap-type: y proximity;
`;

export class BengalPage extends React.Component {

    render() {

        return (
            <ThemeProvider theme={theme}>
                <Header />
                <BengalPageWrapper>
                    <BengalHeaderBlock />
                    <BengalTextBlock />
                    <BengalOpenSourceBlock />
                    <BengalSpecBlock />
					<BengalBuildBlock />
					<BengalBuyBlock />
                    <ContactBlock />
                </BengalPageWrapper>
            </ThemeProvider>
        )
    }
}