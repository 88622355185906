import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { HeroParagraph, Paragraph, ParagraphSmall } from '../theme/typography';
import { Button  } from './Button';
import { TextLink } from './TextLink';
import { PageTitle } from './PageTitle';
import { StandardSectionContentContainer, StandardSection } from './StandardSection';
import { ContactBlock } from './ContactBlock';
import { ServalActionItemBlock } from './ServalActionItemBlock';
import { Breadcrumbs, BreadcrumbChevron, BreadcrumbLink } from './Breadcrumbs';

import MaverickExampleImg from '../images/fonts/maverick-example.webp';
import MaverickTressymImg from '../images/fonts/maverick-tressym-1-1.webp';
import MaverickBetaflightImg from '../images/fonts/maverick-betaflight-1-1.webp';

const StyledHeroImage = styled.img`
	border-radius: 0.25rem;
	width: 100%;
`;

const ImageWithCutoff = styled(StyledHeroImage)`
	aspect-ratio: 1/1.5;
	object-fit: cover;
	object-position: top;
`;

const ButtonContainer = styled.div`
	align-items: stretch;
	margin-bottom: 2rem;
	margin-top: 2rem;
	max-width: 32rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const TextContainer = styled.div`
	max-width: 32rem;
`;

const Aside = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.neutral4};
	border-left: 1px solid ${(props) => props.theme.colors.neutral6};
	padding-left: 1rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		padding-left: 1rem;
	}
`;

const VersionList = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (orientation:landscape) {
		flex-direction: row;
		gap: 1.5rem;

		@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
			gap: 2rem;
		}
	}
`;

const VersionListItem = styled.div`

	@media only screen and (orientation:landscape) {
		align-items: center;
		display: flex;
		flex-direction: column;
	}
`;

export class MaverickPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<StandardSection>
					<StandardSectionContentContainer>
						<Breadcrumbs>
								<BreadcrumbLink to="/fonts">
									Fonts
								</BreadcrumbLink>
								<BreadcrumbChevron>&gt;</BreadcrumbChevron>
						</Breadcrumbs>
						<PageTitle>Maverick</PageTitle>
						<HeroParagraph>A free font for HDZero goggles, inspired by the F/A-18 Hornet's HUD.</HeroParagraph>
						<ButtonContainer>
							<Button 
								as="a"
								href={`${process.env.PUBLIC_URL}/fonts/maverick-tressym-1-1.bmp`}
								label="Download Maverick for free"
								download
							/>
						</ButtonContainer>
						<StyledHeroImage src={MaverickExampleImg} alt="Maverick font example" />
						<TextContainer>
							<Paragraph>Maverick evokes the cold pragmatism of a warplane cockpit. No embellishments, no metaphors. Everything is direct and to the point.</Paragraph>
							<Paragraph>Its main inspiration is the font in the HUD and screens of the <TextLink link="https://github.com/0x408/hornet-display" label="F/A-18 Hornet" />. However, it also incorporates some lessons from <TextLink link="https://b612-font.com/" label="B612" />, Airbus's open source cockpit font.</Paragraph>
							<Aside>B612 is a fascinating font and you should <TextLink link="https://b612-font.com/" label="totally look it up" />. It was based on a research project where Airbus worked together with ENAC (the French National University of Civil Aviation) and Université de Toulouse III to design a font specifically for aircraft cockpit screens. It was designed to ensure legibility and readability in this very specific context, and was proven to reduce visual fatigue and cognitive load.</Aside>
							<Paragraph>I designed Maverick with obsessive attention to detail. Every pixel was deliberate. My goal was to maintain a consistent visual style throughout the entire font. I used pixel art antialiasing techniques to carefully calculate the right brightness for each pixel of each line.</Paragraph>
							<Paragraph>Maverick is open source. Feel free to use or remix it, just link back to this page when you do! I'm releasing it under the <TextLink href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" label="Creative Commons-Attribution-Share Alike" /> license. There are two versions, both are free to download:</Paragraph>
						</TextContainer>
						<VersionList>
							<VersionListItem>
								<ImageWithCutoff src={MaverickTressymImg} alt="Maverick with Tressym logo" />
								<ButtonContainer>
									<Button 
										as="a"
										download
										href={`${process.env.PUBLIC_URL}/fonts/maverick-tressym-1-1.bmp`}
										label="Download With Tressym logo" 
									/>
								</ButtonContainer>
							</VersionListItem>
							<VersionListItem>
								<ImageWithCutoff src={MaverickBetaflightImg} alt="Maverick with Betaflight logo" />
								<ButtonContainer>
									<Button
										as="a"
										colorContext="light"
										download
										href={`${process.env.PUBLIC_URL}/fonts/maverick-betaflight-1-1.bmp`}
										label="Download With Betaflight logo" 
										variant="outlined"
									/>
								</ButtonContainer>
							</VersionListItem>
						</VersionList>
						<TextContainer>
							<Paragraph>
							For more information on how to start using Maverick in your HDZero goggles, check out the <TextLink link="https://github.com/hd-zero/hdzero-osd-font-library" label="instructions in the HDZero OSD font library" />.</Paragraph>
						</TextContainer>
					</StandardSectionContentContainer>
				</StandardSection>
				<ServalActionItemBlock />
				<ContactBlock />
			</ThemeProvider>
		)
	}
}