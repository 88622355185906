import React from "react";
import styled from 'styled-components';

import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { ParagraphSmall } from "../theme/typography";

const StyledServalSpecBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	padding: 1.75rem 1.5rem;
	max-width: 32rem;
	margin: 0 auto;

	:first-child {
		margin-top: 0;
	}

	${LabeledDataRow} {
		margin-top: 2rem;
	}
`;

const Title = styled.h3`
	${(props) => props.theme.typography.headingL};
	margin-top: 0;
`;

const Info = styled.h3`
	${(props) => props.theme.typography.headingS};
	margin-bottom: 0.5rem;
	margin-top: 2rem;
`;

const Example = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.neutral4};
	margin-top: 0.5rem;
`;

export class ServalSpecBlock extends React.Component {

	render() {
		return (
			<StyledServalSpecBlock>
				<ContentContainer>
					<Title>Specifications</Title>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Stack mounting"
							data="20x20"
						/>
						<LabeledData
							size="m"
							colorContext="light"
							label="Motor mounting"
							data="16 mm"
						/>
					</LabeledDataRow>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Stack height"
							data="23 mm"
						/>
					</LabeledDataRow>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Material"
							data="T300 carbon fiber"
							supplemental='Plain weave, matte finish.<br/>Would you prefer T700 instead? <a href="mailto:contact@tressym.eu?subject=T700 Serval">Let me know!</a>'
						/>
					</LabeledDataRow>
					<LabeledDataRow>
						<LabeledData
							size="m"
							colorContext="light"
							label="Arm thickness"
							data="5 mm"
						/>
						<LabeledData
							size="m"
							colorContext="light"
							label="Plate thickness"
							data="2 mm"
						/>
					</LabeledDataRow>
					<Info>Fits most 20x20 ESCs</Info>
					<Example>For example: Foxeer Reaper Slim 60A, Flycolor Trinx G20, Diatone Mamba F65_128K Mini, Diatone Mamba 5 Reactor_66.</Example>
					<Info>Fits HDZero Nano 90, and most Nano analog cameras</Info>
					<Example>For example: Foxeer Predator Nano.</Example>
				</ContentContainer>
			</StyledServalSpecBlock>
		);
	}
}