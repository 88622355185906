import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { HeroParagraph, MainTitle, ParagraphSmall } from "../theme/typography";

import BengalImageHorizontal from '../images/bengal/bengal-header-block-bgr-horizontal.webp';
import BengalImageVertical from '../images/bengal/bengal-header-block-vertical.webp';
import products from '../data/products.json';

const StyledServalBlock = styled.div`
	background:
		linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.5) 95%),
		url(${BengalImageVertical}) no-repeat center/cover;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100dvh - 3rem);
	overflow: hidden;
	padding: 1.75rem 1.5rem;
	position: relative;
	text-align: center;
	z-index: 0;

	@media screen and (orientation:landscape) {
		background: 
			linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 15%),
			url(${BengalImageHorizontal}) no-repeat center/cover;
		justify-content: center;
		text-align: left;
	}

	@media screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		padding: 1.75rem 3rem;
	}
`;

const TopContent = styled.div`
	z-index: 1;
`;

const BottomContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-items: flex-start;
		width: 26.5rem;
	}
`;

const PriceRow = styled(LabeledDataRow)`
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		max-width: 24rem;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		max-width: 28rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 1.5rem;
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
		justify-content: center;
	}

	@media screen and (orientation:landscape) {
		justify-content: flex-start;
	}
`;

const BuyButton = styled(Button)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 auto;
	}
`;

const ServalHero = styled(HeroParagraph)`
	@media screen and (max-width: ${(props) => props.theme.breakpoints.first}) {
		${(props) => props.theme.typography.bodyXS};
	}
`;

const ButtonAndDescription = styled.div``;

const ButtonDescription = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.yellow6};
	margin-top: 1rem;
	margin-bottom: 0;
`;

export class BengalHeaderBlock extends React.Component {

	render() {
		const data = products.find((product) => product.id === "bengal");

		return (
			<StyledServalBlock>
				<TopContent>
					<MainTitle>bengal</MainTitle>
					<ServalHero>Fun, fast, friendly, and dependable.<br/>A perfect frame for your first toothpick build.</ServalHero>
				</TopContent>
				<BottomContent>
					<PriceRow>
						{data.prices.map((price) => (
							<LabeledData
								key={price.priceName}
								label={price.priceName}
								data={`€${price.priceEUR}`}
								description={`${price.priceHUF} HUF`}
							/>
						))}
					</PriceRow>
					<ButtonAndDescription>
						<ButtonContainer>
							<BuyButton label="Buy a Bengal" link={data.buyLink}/>
						</ButtonContainer>
						<ButtonDescription>{data.stockStatus.text}</ButtonDescription>
					</ButtonAndDescription>
				</BottomContent>
			</StyledServalBlock>
		);
	}
}