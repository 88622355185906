import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { Card } from './Card';
import { PageTitle } from './PageTitle';
import { StandardSection, StandardSectionContentContainer } from './StandardSection';
import { CardContainer } from './CardContainer';
import servalImage from '../images/serval/serval-front.webp';
import bengalImage from '../images/bengal/bengal-front.webp';

const FrameCard = styled(Card)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
    	flex: 1 1 50%;
	}
`;

export class FramesPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<StandardSection>
					<StandardSectionContentContainer>
						<PageTitle>Frames</PageTitle>
						<CardContainer>
							<FrameCard 
								to="/frames/serval" 
								background={servalImage} 
								title="Serval" 
								description="A featherweight 5-inch racing frame."
							/>
							<FrameCard 
								to="/frames/bengal" 
								background={bengalImage} 
								title="Bengal" 
								description="A fun and friendly 2.5-inch toothpick."
							/>
						</CardContainer>
					</StandardSectionContentContainer>
				</StandardSection>
			</ThemeProvider>
		)
	}
}