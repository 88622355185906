import React from "react";
import styled from 'styled-components';

import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";

const StyledBengalBuildBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral1};
	color: ${(props) => props.theme.colors.neutral10};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	padding: 1.75rem 1.5rem;
	max-width: 32rem;
	margin: 0 auto;

	:first-child {
		margin-top: 0;
	}

	${LabeledDataRow} {
		margin-top: 2rem;
	}
`;

const Title = styled.h3`
	${(props) => props.theme.typography.headingL};
	margin-top: 0;
`;

const Build = styled.div``;

export class BengalBuildBlock extends React.Component {

	render() {
		return (
			<StyledBengalBuildBlock>
				<ContentContainer>
					<Title>Suggested starter build</Title>
					<Build>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="Flight controller"
								data="JHEMCU GHF435AIO 20A 2-4S"
								supplemental="A super cheap AIO with a built-in ELRS receiver. Go for the version with an U.FL connector for an external antenna."
							/>
						</LabeledDataRow>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="Video transmitter"
								data="Happymodel OVX300"
								supplemental="A very light VTX, with great value for its price. Extensively tested and widely recommended."
							/>
						</LabeledDataRow>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="Camera"
								data="Caddx Ant Nano"
								supplemental="A light Nano camera that works well for its price."
							/>
						</LabeledDataRow>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="Motors"
								data="Any 1103 8000KV motors"
								supplemental="I don't have a specific recommendation."
							/>
						</LabeledDataRow>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="Propellers"
								data="Gemfan 2520-3"
								supplemental="Delivers great punch for dynamic freestyle. Silent and smooth, with low vibration and acceptable propwash handling."
							/>
						</LabeledDataRow>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="Batteries"
								data="Any 3S 550mAh battery"
								supplemental="I don't have a specific recommendation. 450mAh batteries could also work, for a lighter, potentially more agile build with less flight time."
							/>
						</LabeledDataRow>
						<LabeledDataRow>
							<LabeledData
								size="m"
								colorContext="dark"
								label="GPS"
								data="BZGNSS BZ-121"
								supplemental="Finds satellites fast. Mount on outside of arm for best results."
							/>
						</LabeledDataRow>
					</Build>
				</ContentContainer>
			</StyledBengalBuildBlock>
		);
	}
}