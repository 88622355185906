import React from "react";
import styled from 'styled-components';

import { HeroParagraph } from "../theme/typography";
import FeatureBlock from "./FeatureBlock";

import intro1Image from '../images/bengal/bengal-features-1.webp';
import intro2Image from '../images/bengal/bengal-features-2.webp';
import intro3Image from '../images/bengal/bengal-features-3.webp';
import intro4Image from '../images/bengal/bengal-features-4.webp';
import intro5Image from '../images/bengal/bengal-features-5.webp';
import intro6Image from '../images/bengal/bengal-features-6.webp';

const StyledServalTextBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex: 1 1 auto;
	max-width: 32rem;
	padding: 1.5rem;
	margin: 0 auto;
	flex-direction: column;
	justify-content: space-between;

	:first-child {
		margin-top: 0;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		flex-direction: row;
		gap: 3rem;
		max-width: 120rem;
		padding: 3rem;
		overflow: visible;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		height: 100%;
		min-height: 0;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingL};
	margin-bottom: 1.5rem;
`;

const LeftColumn = styled.div`
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-self: stretch;
		flex: 1 1 25%;
		overflow-y: visible;
	}
`;

const HeadingAndHeroContainer = styled.div`
    @media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
        position: sticky;
        top: 3rem;
    }
`;

const FeaturesContainer = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
    flex: 1 1 75%;
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;

    ${FeatureBlock} {
      flex: 0 0 calc((100% - 3rem) / 2);
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.fifth}) {
  
    ${FeatureBlock} {
	  flex: 0 0 calc((100% - 4rem) / 3);
	}
  }
`;

export class BengalTextBlock extends React.Component {

	render() {
		return (
			<StyledServalTextBlock>
				<ContentContainer>
					<LeftColumn>
						<HeadingAndHeroContainer>
							<Heading>Build for dirt cheap,<br/>fly forever</Heading>
							<HeroParagraph>Build your starter drone for as little as 100 Euros.</HeroParagraph><HeroParagraph>Or, go high-end with a DJI O4 Lite and 4K 60fps video. </HeroParagraph><HeroParagraph>Either way, this frame is a perfect start for a fun outdoor freestyle build.</HeroParagraph>
						</HeadingAndHeroContainer>
					</LeftColumn>
					<FeaturesContainer>
						<FeatureBlock 
							image={intro1Image}
							title="Friendly and cute"
							description="Small, silent, and non-threatening. Less noise, less hassle, more fun."
						/>
						<FeatureBlock 
							image={intro2Image}
							title="Agile and fun"
							description="With 2.5 inch propellers, a Bengal can pack a surprising punch."
						>
						</FeatureBlock>
						<FeatureBlock 
							image={intro3Image}
							title="Very efficient"
							description="A light frame means long flights - up to 7 minutes on a 3S 550 mAh battery."
						>
						</FeatureBlock>
						<FeatureBlock 
							image={intro4Image}
							title="Great for exploring"
							description="Lost signal? No problem. Mount a GPS on the arm for peace of mind."
						/>
						<FeatureBlock 
							image={intro5Image}
							title="Built to endure"
							description="The Bengal's base plate is made of durable T700 carbon fiber. The sturdy TPU canopy protects the camera and lens against scratches."
						/>
						<FeatureBlock 
							image={intro6Image}
							title="Easy to upgrade"
							description="Start with analog, then upgrade to digital. The Bengal can stay in your fleet for a long time."
						/>
					</FeaturesContainer>
				</ContentContainer>
			</StyledServalTextBlock>
		);
	}
}