import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import { HeroParagraph, Paragraph, ParagraphSmall } from "../theme/typography";

import ServalOpenSourceBackground from '../images/serval/serval-blueprint-isometric.svg';
import products from '../data/products.json';

const StyledBlock = styled.section`
	background: 
		linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 100%),
		url(${ServalOpenSourceBackground}) no-repeat center/cover;
	background-color: ${(props) => props.theme.colors.neutral0};
	color: ${(props) => props.theme.colors.neutral10};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	min-height: calc(100dvh - 3rem);
	padding: 1.5rem;
	margin: 0 auto;
	max-width: 32rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.fifth}) {
		min-height: auto;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
`;

const FlexButton = styled(Button)`
	flex: 0 0 auto;
`;

const Heading = styled.h3`
	margin-bottom: 0;
	margin-top: 0;
	${(props) => props.theme.typography.headingL};
`;

const TextLink = styled.a`
	color: ${(props) => props.theme.colors.purple6};
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}
`;

const Addendum = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.neutral6};
`;

const TopContent = styled.div``;

const BottomContent = styled.div``;

export class ServalOpenSourceBlock extends React.Component {

	render() {
		const servalData = products.find((product) => product.id === "serval");

		return (
			<StyledBlock>
				<ContentContainer>
					<TopContent>
						<Heading>Always in stock,<br/>forever supported</Heading>
					</TopContent>
					<BottomContent>
						<HeroParagraph>The Serval’s entire design is open source. You can buy a Serval from us, or download the designs for free and cut it yourself. This guarantees fair prices forever.</HeroParagraph>
						<Paragraph>But it’s still better to buy from us! Thanks to bulk ordering, we can keep prices low — and when you buy directly, you also support the Serval's further development.</Paragraph>
						<Paragraph>Are you missing the perfect fin, camera mount, or antenna mount? You can easily duplicate and edit the source files in Onshape. Let’s innovate together!</Paragraph>
						<Addendum>Files are published under the <TextLink href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">Creative Commons-Attribution-Share Alike</TextLink> license.</Addendum>
						<ButtonContainer>
							<FlexButton colorContext="dark" variant="outlined" label="Edit design" target="_blank" link={servalData.onshapeLink}/>
						</ButtonContainer>
					</BottomContent>
				</ContentContainer>
			</StyledBlock>
		);
	}
}