import styled from 'styled-components';

export const Carousel = styled.div`
	display: flex;
	flex-flow: row nowrap;
	gap: 1.5rem;
	margin: 0 -1.5rem;
	padding: 0 1.5rem;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		gap: 2rem;
	}
`;

export const CarouselItem = styled.div`
	flex: 0 0 calc(100% - 3rem);
	scroll-snap-align: center;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 calc(50% - 3rem);
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.fourth}) {
		flex: 1;
		margin-right: 0;
	}
`;