import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { HeroParagraph, MainTitle, ParagraphSmall } from "../theme/typography";

import ServalVideo from '../images/serval/video-website-low.mp4';
import products from '../data/products.json';

const StyledServalBlock = styled.div`
	background-color: #000;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100dvh - 3rem);
	overflow: hidden;
	padding: 1.75rem 1.5rem;
	position: relative;
	text-align: center;
	z-index: 0;

	@media screen and (orientation:landscape) {
		justify-content: center;
		text-align: left;
	}

	@media screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		padding: 1.75rem 3rem;
	}
`;

const TopContent = styled.div`
	z-index: 1;
`;

const BottomContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-items: flex-start;
		width: 28rem;
	}
`;

const PriceRow = styled(LabeledDataRow)`
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		max-width: 24rem;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		max-width: 28rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 1.5rem;
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
		justify-content: center;
	}

	@media screen and (orientation:landscape) {
		justify-content: flex-start;
	}
`;

const BuyButton = styled(Button)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 auto;
	}
`;

const StyledVideo = styled.video`
	height: 100%;
	left: 50%;
	object-fit: cover;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: -1;

	@media screen and (orientation:landscape) {
		left: 60%;
		top: 50%;
	}
`;

const ServalHero = styled(HeroParagraph)`
	@media screen and (max-width: ${(props) => props.theme.breakpoints.first}) {
		${(props) => props.theme.typography.bodyXS};
	}
`;

const ButtonAndDescription = styled.div``;

const ButtonDescription = styled(ParagraphSmall)`
	color: ${(props) => props.theme.colors.yellow6};
	margin-top: 1rem;
	margin-bottom: 0;
`;

export class ServalHeaderBlock extends React.Component {
	render() {
		const servalData = products.find((product) => product.id === "serval");

		return (
			<StyledServalBlock>
				<StyledVideo 
					autoPlay 
					loop 
					muted
					playsInline
					src={ServalVideo}
				></StyledVideo>
				<TopContent>
					<MainTitle>{servalData.name}</MainTitle>
					<ServalHero>A featherweight 5-inch racing frame.<br/>Lighter than <em>light,</em> sturdier than <em>ultralight.</em></ServalHero>
				</TopContent>
				<BottomContent>
					<PriceRow>
						{servalData.prices.map((price) => (
							<LabeledData
								key={price.priceName}
								label={price.priceName}
								data={`€${price.priceEUR}`}
								description={`${price.priceHUF} HUF`}
							/>
						))}
					</PriceRow>
					<ButtonAndDescription>
						<ButtonContainer>
							<BuyButton label="Buy a Serval" link={servalData.buyLink} />
						</ButtonContainer>
						<ButtonDescription>{servalData.stockStatus.text}</ButtonDescription>
					</ButtonAndDescription>
				</BottomContent>
			</StyledServalBlock>
		);
	}
}