import styled from 'styled-components';
import { Card, CardTitle } from './Card';

export const PartCard = styled(Card)`
    flex: 1 1 50%;
	max-height: unset;

	&::before {
        content: "";
        display: block;
        padding-top: 150%; /* 2:3 aspect ratio */
    }

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.fourth}) {

		${CardTitle} {
			${(props) => props.theme.typography.headingL};
			}
		}
`;